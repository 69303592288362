.products {
    width: 100%;
    background: #FFF;
}

.products_container {
    padding: 50px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.products_container h3 {
    font-size: 40px;
    font-weight: 500;
    color: #4c4c4c;
    text-align: center;
}

.products_container h3 span {
    color: #204785;
    text-align: center;
}

.Coprojects_container {
    display: grid;
    margin-top: 50px;
    margin-bottom: 30px;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.Coprojects_container img {
    width: 220px;
    height: 220px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
}

.projects_image_view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

.Coprojects_container_content {
    display: flex;
    flex-direction: column;

}

.Coprojects_container_name {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #204785; */
}

.Coprojects_container_name span {
    font-size: 18px;
}

.projects_image_view_no {
    display: none;
}

.projects_image_view img {
    width: 30%;
}

@media screen and (max-width: 1000px) {
    .Coprojects_container_name span {
        font-size: 16px;
    }

    .Coprojects_container {
        grid-template-columns: repeat(2, 1fr);
        gap: 5 px;
    }

    .Coprojects_container img {
        width: 160px;
        height: 160px;
    }

    .projects_image_view img {
        width: 60%;
    }
}