.navbar {
    width: 100%;
    position: sticky;
    background: #204785;
    z-index: 10000;
    top: 0;
}

.hamburger {
    display: none;
}

.navbar.scrolled {
    background: #204785;
    backdrop-filter: blur(15px);
}

.nav_container {
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.nav_logo {
    width: 100px;
    cursor: pointer;

}

.nav_sections {
    display: flex;
    gap: 20px;
}

.nav_section {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    color: #ffffff;
    position: relative;
}

.nav_sections_ar {
    display: flex;
    gap: 20px;
    flex-direction: row-reverse;
}

.nav_section_ar {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    color: #ffffff;
    position: relative;

}

.nav_section:hover {
    color: #d3d3d3;
}

.nav_section_ar:hover {
    color: #d3d3d3;
}

.nav_section p:hover::after {
    content: "";
    width: 50%;
    height: 2px;
    background: #ffffff;
    position: absolute;
    bottom: -3px;
    left: 1px;
}

.nav_section_ar p:hover::after {
    content: "";
    width: 50%;
    height: 2px;
    background: #ffffff;
    position: absolute;
    bottom: -3px;
    right: 1px;
}

.lang {
    display: flex;
    gap: 5px;
    cursor: pointer;
    color: #ffffff;
}

@media screen and (max-width: 1000px) {
    .nav_sections {
        display: none;
    }

    .nav_sections_ar {
        display: none;
    }

    .hamburger {
        display: flex;
        margin-right: 20px;
        z-index: 1100;
    }

    .lang {
        display: none;
    }

    .navbar {
        display: flex;
        position: sticky;
        align-items: center;
        justify-content: space-between;
    }

    .menu {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 75%;
        background-color: #204785c9;
        backdrop-filter: blur(8px);
        z-index: 1000;
        -webkit-box-shadow: -300px -3px 45px 21px #2047853e;
        -moz-box-shadow: -300px -3px 45px 21px #2047853e;
        box-shadow: -300px -3px 45px 21px #2047853e;
        justify-content: right;
    }

    .nav_sections_menu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: right;
        padding: 100px 30px;
    }

    .nav_section_ar {
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 50px;
        font-size: 20px;
        font-weight: 400;

        padding: 18px;
        transition: 300ms;
    }

    .nav_section_ar:hover {
        color: #ffffff;
    }

    .lang_menu {
        display: flex;
        gap: 5px;
        color: #ffffff;
        margin-top: 10px;
        padding: 10px 20px;
    }
}