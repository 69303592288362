.aboutus {
    width: 100%;
    background: #FFF;
}

.about_containre {
    padding: 100px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about_containre h3 {
    font-size: 40px;
    font-weight: 500;
    color: #4c4c4c;
}

.about_containre h3 span {
    color: #204785;
}

.about_text_content {
    width: 90%;
    margin-top: 50px;
}

.about_text_content p {
    font-size: 20px;
    text-align: center;
    color: #4c4c4c;
}

.about_text_content p::selection {
    color: #FFF;
    background: #204785;
}

.about_containre h3::selection {
    color: #FFF;
    background: #204785;
}

.about_containre h3 span::selection {
    background: #292929;
}

.about_image_content {
    display: flex;
    margin-top: 50px;
}

.about_image_content img {
    border-radius: 32% 68% 57% 43% / 30% 30% 70% 70%;
    width: 400px;
    transition: 600ms;
    /* border: solid 5px #E95B5A; */
}

.about_image_content img:hover {
    border-radius: 62% 38% 77% 23% / 44% 40% 60% 56%;
}

.about_sections {
    display: flex;
    margin-top: 50px;
    padding: 0 60px;
    gap: 10px;
}

.about_section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;
    gap: 10px;
}

.about_section h4 {
    font-size: 24px;
    font-weight: 500;
    color: #204785;
}

.about_section p {
    font-size: 20px;
    color: #4c4c4c;
}

.about_section p::selection {
    color: #FFF;
    background: #204785;
}

.about_section h4::selection {
    background: #292929;
}



.about_sections_en {
    display: flex;
    margin-top: 50px;
    padding: 0 60px;
    gap: 10px;
}

.about_section_en {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    gap: 10px;
}

.about_section_en h4 {
    font-size: 24px;
    font-weight: 500;
    color: #204785;
}

.about_section_en p {
    font-size: 20px;
    color: #4c4c4c;
}

.about_section_en p::selection {
    color: #FFF;
    background: #204785;
}

.about_section h4::selection {
    background: #292929;
}

@media screen and (max-width: 720px) {

    .about_containre {
        padding: 15px 10px;
    }

    .about_containre h3 {
        text-align: center;
    }

    .about_text_content {
        width: 100%;
        margin-top: 30px;
    }

    .about_text_content p {
        font-size: 18px;
    }

    .about_image_content {
        margin-top: 30px;
    }

    .about_image_content img {
        width: 300px;
    }

    .about_sections {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        padding: 5px;
        gap: 20px;
    }


    .about_section h4 {
        font-size: 20px;
    }

    .about_section p {
        font-size: 18px;
    }

    .about_sections_en {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        padding: 10px;
    }

    .about_section_en h4 {
        font-size: 20px;
    }

    .about_section_en p {
        font-size: 18px;
    }

}