.homeCom {
    width: 100%;
    background-image: url('../../assets//construction/cover.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home_container {
    padding: 100px 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffffc0;
}

.home_container_ar {
    padding: 100px 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row-reverse;
    background-color: #ffffffc0;
}

.logo_co {
    width: 400px;
}

.text_content {
    display: flex;
    flex-direction: column;
}

.text_content_ar {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.text_content h1 {
    font-size: 70px;
    font-weight: 600;
    color: #204785;
    margin: 0;
    font-family: sans-serif;

}

.text_content_ar h1 {
    font-size: 70px;
    font-weight: 600;
    color: #204785;
    margin: 0;
    text-align: right;
    font-family: sans-serif;
}

.text_content h1::selection {
    background: #204785;
}

.text_content_ar h1::selection {
    background: #204785;
}

.text_content h2 {
    font-size: 50px;
    font-weight: 400;
    color: #4c4c4c;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.text_content_ar h2 {
    font-size: 40px;
    font-weight: 400;
    color: #4c4c4c;
    text-align: right;
}

.text_content h2::selection {
    background: #204785;
    color: #FFF;
}

.text_content_ar h2::selection {
    background: #204785;
    color: #FFF;
}

.graph {
    width: 550px;
    margin-top: 30px;
}

.graph_ar {
    width: 600px;
    margin-top: 30px;
}

.graph p {
    font-size: 20px;
    color: #000000;
    font-weight: 500;
}

.graph_ar p {
    font-size: 20px;
    color: #000000;
    text-align: right;
    font-weight: 500;
}

.graph_ar p::selection {
    background: #204785;
    color: #FFF;
}

.graph p::selection {
    background: #406cb28a;
    color: #FFF;
}

.btn_contact {
    margin-top: 30px;
    padding: 15px;
    width: 150px;
    background-color: #204785;
    align-items: center;
    align-content: center;
    text-align: center;
    border-radius: 100px;
    cursor: pointer;
    transition: 300ms;
}

.btn_contact:hover {
    -webkit-box-shadow: 0 0 20px 1px #406cb28a;
    -moz-box-shadow: 0 0 20px 1px #406cb28a;
    box-shadow: 0 0 20px 1px #406cb28a;
}

.btn_contact span {
    font-size: 16px;
    font-weight: 600;
    color: #FFF;
    cursor: pointer;
}

.image_content {
    display: flex;
}

.image_content img {
    border-radius: 32% 68% 57% 43% / 30% 30% 70% 70%;
    width: 400px;
    transition: 600ms;
    /* border: solid 5px #E95B5A; */
}

.image_content img:hover {
    border-radius: 62% 38% 77% 23% / 44% 40% 60% 56%;
}

@media screen and (max-width: 1000px) {
    .home_container {
        padding: 20px 15px;
        flex-direction: column;
    }

    .logo_co {
        width: 300px;
    }

    .home_container_ar {
        padding: 15px 15px;
        flex-direction: column;
    }


    .text_content h1 {
        font-size: 50px;
    }

    .text_content_ar h1 {
        font-size: 50px;
        font-weight: 600;
    }

    .text_content h2 {
        font-size: 30px;
    }

    .text_content_ar h2 {
        font-size: 30px;
    }

    .graph {
        width: 90%;
        margin-top: 30px;
    }

    .graph_ar {
        width: 95%;
        margin-top: 30px;
    }

    .graph p {
        font-size: 16px;
    }

    .graph_ar p {
        font-size: 16px;
    }



    .btn_contact {
        display: none;
    }

    .image_content img {
        width: 300px;
        margin-top: 30px;
        margin-bottom: 40px;
    }

    .image_content img:hover {
        border-radius: 62% 38% 77% 23% / 44% 40% 60% 56%;
    }
}