::-webkit-scrollbar {
  width: .8rem;
  height: 0.9rem;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #dee8f9;
  /* border-radius: 100vw;
    margin-block: .5rem; */
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #204785, #3b78da);
  border-radius: 100vw;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #3b78da, #204785);
}