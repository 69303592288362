.services {
    width: 100%;
    background: #FFF;
}

.services_container {
    padding: 50px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.services_container h3 {
    font-size: 40px;
    font-weight: 500;
    color: #4c4c4c;
}

.services_container h3 span {
    color: #204785;
}

.services_tap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin-top: 80px;
    position: relative;
}

.services_tap .tapSpan {
    font-size: 18px;
    cursor: pointer;
    position: relative;
    transition: 200ms;

}

.services_tap .tapSpan:hover {
    color: #204785;
}

.services_tap .tapSpan_active {
    font-size: 18px;
    cursor: pointer;
    position: relative;
    color: #204785;

}

.services_tap::after {
    content: "";
    width: 60%;
    height: 1px;
    background: #a1a1a1;
    position: absolute;
    bottom: -10px;
    transition: 400ms;
    /* left: 1px; */
}

.services_tap .tapSpan_active::after {
    content: "";
    width: 100%;
    height: 4px;
    left: 0;
    background: #204785;
    position: absolute;
    bottom: -16px;
    z-index: 1000;
    border-radius: 10px;

}

.services_section_hide {
    display: none;
}

.services_section {
    width: 90%;
    display: flex;
    margin-top: 50px;
    padding: 0 50px;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.services_section .content {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services_section_ar .content {
    display: flex;
    gap: 30px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.services_section_ar {
    width: 90%;
    display: flex;
    margin-top: 50px;
    padding: 0 50px;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-direction: row-reverse;
}

.services_section_ar p {
    font-size: 20px;
    color: #4c4c4c;
    text-align: right;
    text-align: center;
}

.services_section_ar p::selection {
    color: #FFF;
    background: #204785;
}

.services_section p {
    font-size: 20px;
    color: #4c4c4c;
    text-align: center;
}

.services_section p::selection {
    color: #FFF;
    background: #204785;
}

.services_image_content {
    display: flex;
    margin-top: 50px;
}

.services_image_content img {
    border-radius: 32% 68% 57% 43% / 30% 30% 70% 70%;
    width: 400px;
    transition: 600ms;
    /* border: solid 5px #E95B5A; */
}

.services_image_content img:hover {
    border-radius: 62% 38% 77% 23% / 44% 40% 60% 56%;
}

.content_allImages {
    display: grid;
    margin-top: 50px;
    margin-bottom: 30px;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.content_allImages img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.content_allImages img:hover {
    border-radius: 8px;
}

@media screen and (max-width: 1000px) {
    .services_container {
        padding: 30px 40px;
    }

    .services_container h3 {
        font-size: 30px;
        text-align: center;
    }

    .services_tap {
        width: 100%;
        gap: 50px;
        margin-top: 50px;
    }

    .services_tap .tapSpan {
        font-size: 12px;

    }

    .services_tap .tapSpan_active {
        font-size: 12px;

    }

    .services_tap::after {
        content: "";
        width: 100%;
    }

    .services_section {
        width: 100%;
        flex-direction: column;
        margin-top: 50px;
        gap: 20px;
    }


    .services_section_ar {
        width: 100%;
        flex-direction: column;
        margin-top: 50px;
        gap: 20px;
    }

    .services_section_ar p {
        font-size: 18px;
        text-align: center;
    }

    .services_section p {
        font-size: 18px;
        text-align: center;
    }

    .services_image_content {
        margin-top: 30px;
    }

    .services_image_content img {
        width: 300px;
    }

    .content_allImages {
        margin-top: 10px;
        margin-bottom: 30px;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
    }

    .content_allImages img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
    }
}

@media screen and (max-width: 500px) {
    .services_container {
        padding: 20px 30px;
    }


    .services_tap {
        width: 100%;
        gap: 20px;
        margin-top: 50px;
    }

    .content_allImages {
        margin-top: 10px;
        margin-bottom: 30px;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }
}