.contact_container {
    padding: 50px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact_container h3 {
    font-size: 40px;
    font-weight: 500;
    color: #204785;
}

.contact_sections {
    margin-top: 50px;
    display: flex;
    gap: 40px;
    margin-bottom: 30px;
}

.contact_section {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.contact_section p {
    font-size: 18px;
}

.contact_section .ico {
    color: #204785;
}

@media screen and (max-width: 1000px) {
    .contact_container {
        padding: 30px 40px;
    }

    .contact_container h3 {
        font-size: 30px;
    }

    .contact_sections {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .contact_section p {
        font-size: 16px;
    }
}